<template>
    <admin-default v-slot:admin>
        <div class="application">
            <div class="container container-resize">
                <div class="row border-bottom pb-5">
                    <div class="col-md-9"><h1 class="application__secondary--title">Pay Loan</h1></div>
                    <div class="col-6 col-md-3">
                        <h1 class="application__main--title">Welcome,</h1>
                        <h1 class="application__tertiary--title">{{user.name}}</h1>
                    </div>
                </div>

                <h1 class="text-dark">Card Details</h1>

                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="card" class="form__label">Name on Card</label>
                            <input type="text" class="form-control form__input margin-bottom" id="card" placeholder="Idemudia Precious">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="address" class="form__label">Email Address</label>
                            <input type="email" class="form-control form__input margin-bottom" id="address" placeholder="example@gmail.com">
                        </div>
                    </div>
                </div>

                <label for="Code" class="form__label">Amount to Charge</label>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <select class="form-control form__input form__input--small" id="Code">
                                <option>NGN</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="text" class="form-control form__input margin-bottom" placeholder="100,000.00">
                        </div>
                    </div>
                </div>

                <button class="btn__registration small-btn mt-5 mb-5">Pay Now</button>
            </div>
        </div>
    </admin-default>
</template>

<script>
    import AdminDefault from "../navigate/AdminDefault";
    export default {
        name: "PayLoan",
        components: {AdminDefault}
    }
</script>

<style scoped>

</style>